import React, {Component} from "react"
import Layout from "../components/layout"
import BackgroundHero from "../components/BackgroundHero"
import HeaderContentOffer from "../components/HeaderContentOffer"
import HowTo from "../components/howTo"
import ServicesOffer from "../components/ServicesOffer"
import PriceList from "../components/PriceList"
import Benefits from "../components/Benefits"
import OfferIcon from "../components/OfferIcon";
import SchemeIcon from "../assets/svgIcon/schemat.svg";
import CartIcon from "../assets/svgIcon/karta.svg";
import CodeIcon from "../assets/svgIcon/code.svg";
import CodeWebIcon from "../assets/svgIcon/codeWeb.svg";
import RaquietIcon from "../assets/svgIcon/rakieta.svg";
import ToolsIcon from "../assets/svgIcon/tools.svg";
import AchivmentIcon from "../assets/svgIcon/achivment.svg";
import IdeaIcon from "../assets/svgIcon/idea.svg";
import OfferIconServices from "../components/OfferIconServices";
import WebsitesIcon from "../assets/svgIcon/websites.svg";
import OptymalizationIcon from "../assets/svgIcon/optymalizacja.svg";
import OfferIconInner from "../components/OfferIconInner";
import SEO from "../components/seo"
import PriceListItem from "../components/PriceListItem"


class Website extends Component {
    render() {
        return (
            <Layout footerDesc="Twoja witryna jest często pierwszym doświadczeniem, jakie ludzie mają z Twoją marką.
            Niech twoje pierwsze wrażenie się liczy.
            CodersBros z siedzibą w Kielcach, Polska sprawi, że Twoje strona internetowa zacznie świecić jak gwiazda">
              <SEO title="Strony internetowe Kielce | Projektowanie stron WWW"
                   description="CodersBros to kompleksowa firma z Kielc projektująca strony internetowe.
                    Łączymy strategię nieprzeciętnego designu i technologii Gatsby.js w celu rozwoju stron internetowych.
Zaczynamy tam gdzie zatrzymują się inne agencje."
              />
                <HeaderContentOffer title="Strony Internetowe"
                                    desc="Maksymalizuj swój biznes i poszerz zasięg działania swojej firmy dzięki CodersBros prawdopodobnie
                                  jednej z najlepszych agencji zajmującej się programowaniem stron internetowych w Kielcach.
                                  Dzięki wykorzystaniu wspaniałych narzędzi Gatsby dostarczamy niestandardowe rozwiązania do
                                  projektowania i tworzenia stron internetowych dla Twojej organizacji
                                  Zaczynamy tam gdzie zatrzymują się inne agencje"/>
                <BackgroundHero img="website" />
                <HowTo title="Jak tworzymy strony internetowe?"
                       smallTitle="jak działamy?"
                       desc="
                            Stworzenie strony www wymaga zaangażowania wielu osób oraz zrealizowania kilku
                            etapów. Obecnie strona internetowa to nie tylko wizytówka, jest to często pierwszy element
                            interakcji klienta z firmą, a jak wiadomo pierwsze wrażenie pozostaje w pamięci na długo.
                            Dlatego tak ważne jest aby swoim wykonaniem wyróżniała się ona na tle konkurencji, a klient
                            chętnie do niej powracał. Budujemy strony internetowe nie tylko atrakcyjne wizualnie, ale i
                            przyjazne dla użytkownika."
                >
                    <div className="howTo__inner paddingInside">
                        <OfferIcon title="1. Planowanie" desc="Nasi eksperci ds. projektowania i tworzenia stron internetowych analizują Twój biznes i opracowują plan wykonania projektu profesjonalnej strony WWW  wraz z optymalną funkcjonalnością.">
                            <SchemeIcon />
                        </OfferIcon>
                        <OfferIcon title="2. Projekt" desc="Nasi graficy  opracowują koncepcje wizualne i strategię, aby Twoja strona wyróżniała się na tle innych, co zapewnia lepszy zwrot z inwestycji">
                            <CartIcon />
                        </OfferIcon>
                        <OfferIcon title="3. Kodowanie" desc="Gdy nasz zespół projektowy skończy opracowywać koncepcje wizualne i strategię, nasi programiści  wchodzą w grę i dbają o to, aby funkcjonalności idealnie zaadaptowały się w projekcie.">
                            <CodeWebIcon />
                        </OfferIcon>
                        <OfferIcon title="4. Uruchomienie" desc="Gdy Twoja profesjonalna strona internetowa w końcu wygląda i działa dokładnie tak, jak tego chcesz, udostępniamy ją wszystkim docelowym odbiorcom online.">
                            <RaquietIcon />
                        </OfferIcon>
                    </div>
                </HowTo>
                <ServicesOffer title="Nasze usługi projektowania i tworzenia stron internetowych w Kielcach"
                               smallTitle="co oferujemy?">
                        <OfferIconServices>
                            <WebsitesIcon/>
                            <div className="offerIcon__inner">
                                <h3 className="offerIcon__title">Responsywność</h3>
                                <p className="blank">Według www.statista.com aż 51,98% stron internetowych jest
                                    wyświetlanych na urządzeniach mobilnych. W dzisiejszych czasach
                                    responsywność strony jest jednym z najważniejszych etapów jej
                                    projektowania. Nasz zespól stosuje zasadę mobile first, co pozwala
                                    osiągnąć jak największą użyteczność strony na smartfony i nie
                                    tylko.</p>
                            </div>
                        </OfferIconServices>

                        <OfferIconServices>
                            <CodeIcon/>
                            <OfferIconInner
                                title="Czystość kodu"
                                desc="Przy programowaniu stron internetowych i aplikacji webowych,
                                    szczególną uwagę zwracamy na tzw. &quot;czystość kodu&quot;. To znaczy,
                                    że programujemy zgodnie ze światowymi standardami i wzorcami
                                    projektowymi, co pozwala na łatwiejszy rozwój strony lub aplikacji w
                                    przyszłości."
                            />
                        </OfferIconServices>
                        <OfferIconServices>
                            <OptymalizationIcon/>
                            <OfferIconInner
                                title="Optymalizacja"
                                desc="Każda stworzona strona internetowa przez nas jest
                                    zoptymalizowana pod kątem szybkości oraz standardów seo. Jest
                                    to szczególnie ważne przy pozycjonowaniu strony w
                                    wyszukiwarkach."
                            />
                        </OfferIconServices>
                        <OfferIconServices>
                          <ToolsIcon/>
                            <OfferIconInner
                                title="Rozwój CMS"
                                desc="Dostarczamy rozwiązania programistyczne dla niestandardowego CMS lub na
                                    dowolnych innych platformach CMS pozwala to na swobodne zarządzanie treścią na
                                    stronie."
                            />
                        </OfferIconServices>
                        <OfferIconServices>
                            <AchivmentIcon/>
                            <OfferIconInner
                                title="Nowoczesne technologie"
                                desc="W CodersBros technologie dobieramy pod indywidualnie pod projekt. Dla uzyskania najlepszych efektów używamy najnowocześniejszych dostępnych technologi. Nasz zespół specjalizuję sie w programowaniu stron internetowych w Gatsby.js, React.js, HTML5, CSS3, JavaScript, TypeScript, AngularJS, Node.js, C#"
                            />
                        </OfferIconServices>
                        <OfferIconServices>
                            <IdeaIcon/>
                            <OfferIconInner
                                title="PWA"
                                desc="Strona internetowa lub aplikacja webowa działąjąca w trybie offline, na która wchodzimy poprzez kliknięcie ikonky na ekranie? Dzięki PWA (Progressive Web App) jest to możliwe. Jest to technologia, dzięki której twoja strona internetowa lub aplikacja webowa może zachowywać się jak aplikacja moblina."
                            />
                        </OfferIconServices>
                </ServicesOffer>
                <PriceList title="Ile kosztuje strona internetowa?"
                           smallTitle="ile to kosztuje?"
                >
                  <PriceListItem
                    title="Podstawowy"
                    price="od 950zł"
                    services={[
                      "Gotowy projekt graficzny",
                      "Do 3 podstron statycznych",
                      "Slider zdjęć",
                      "Na podstawie CSS, HTML i JavaScript/Gatsby.js",
                      "Responsywny design, kompatybilny z wieloma urządzeniami",
                      "Formularz kontaktowy",
                      "Mapa google",
                      "Do 5 obrazów",
                      "Napisany zgodnie ze standardami SEO i W3C",
                      "Satysfkacja gwarantowana",
                    ]}
                  />
                  <PriceListItem
                    title="Standardowy"
                    price="od 2000zł"
                    services={[
                      "Gotowy projekt graficzny",
                      "Do 10 podstron",
                      "System CMS",
                      "Single Page Application",
                      "Slider zdjęć",
                      "Na podstawie CSS, HTML i JavaScript/Gatsby.js/Next.js",
                      "Responsywny design, kompatybilny z wieloma urządzeniami",
                      "Formularz kontaktowy",
                      "Mapa google",
                      "Nielimitowana liczba obrazów",
                      "Widgety mediów społecznościowych na żywo",
                      "Integracja czatu",
                      "Dedykowany kierownik projektu",
                      "Napisany zgodnie ze standardami SEO i W3C",
                      "Integracja z Google Analistic",
                      "Satysfkacja gwarantowana",
                    ]}
                  />
                  <PriceListItem
                    title="Premium"
                    price="od 4500zł"
                    services={[
                      "Dedykowany projekt graficzny",
                      "Do 20 podstron",
                      "System CMS",
                      "Single Page Application",
                      "Progresive Web Application",
                      "Moduł rejstracji do newseletera",
                      "Slider zdjęć",
                      "Na podstawie CSS, HTML i JavaScript/Gatsby.js/Next.js",
                      "Integrecja płatności online",
                      "Wielojęzycznośc",
                      "Pasek wyszukiwania",
                      "Responsywny design, kompatybilny z wieloma urządzeniami",
                      "Formularz kontaktowy",
                      "Mapa google",
                      "Nielimitowana liczba obrazów",
                      "Widgety mediów społecznościowych na żywo",
                      "Integracja czatu na żywo",
                      "Dedykowany kierownik projektu",
                      "Integracja z Google Analistic",
                      "Napisany zgodnie ze standardami SEO i W3C",
                      "Satysfkacja gwarantowana",
                    ]}
                  />
                  <PriceListItem
                    title="Dedykowany"
                    price="do ustalenia"
                    services={[
                      "Dedykowany projekt graficzny",
                      "Nieograniczona liczba podstron",
                      "Dydykowany system API",
                      "Integracja z bazą danych",
                      "Stworzenie aplikacji na podstawie wyobrażenia klienta",
                      "Dedykowany system CMS",
                    ]}
                  />
                </PriceList>
                <Benefits title="Korzyści dla Ciebie ze współpracy z CodersBros"
                          smallTitle="dlaczego my?"
                          benefitsArr={["Indywidualny projekt graficzny strony internetowej",
                                        "Gwarancja właściwego funkcjonowania strony WWW czystość kodu itp.",
                                        "Optymalizacja strony dla wyszukiwarek itp. Szybkość, standardy SEO",
                                        "Pomoc i doradztwo w zakresie reklamy internetowej",
                                        "Współpraca w kolejnych etapach rozbudowy strony internetowej"]}
                />

            </Layout>

        )
    }
}


export default Website
